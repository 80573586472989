// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-title {
  margin-top: 15px;
  margin-bottom: 10px;
}

.skills-page ul {
  margin-bottom: 20px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .skills-page .page-split-container {
    margin-right: -50px;
    order: 1;
  }
  .skills-page .divider-2 {
    order: 2;
  }
  .skills-page .heading-container {
    order: 3;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/skills.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF;;AAIE;EACE,mBAAA;EACA,oBAAA;AADJ;AAIE;EACE;IACE,mBAAA;IACA,QAAA;EAFJ;EAKE;IACE,QAAA;EAHJ;EAME;IACE,QAAA;EAJJ;AACF","sourcesContent":[".sub-title {\n  margin-top: 15px;\n  margin-bottom: 10px;\n}\n\n.skills-page {\n  // background-color: var(--dark-grey);\n  ul {\n    margin-bottom: 20px;\n    padding-bottom: 10px;\n  }\n\n  @media (min-width: 768px) {\n    .page-split-container {\n      margin-right: -50px;\n      order: 1;\n    }\n\n    .divider-2 {\n      order: 2;\n    }\n\n    .heading-container {\n      order: 3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
