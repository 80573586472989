// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .page-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.profile-pic-container {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.profile-pic {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1000px) {
  .profile-container {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
  }
  .profile-container p {
    margin-right: auto;
    margin-left: 20px;
    max-width: 650px;
  }
}

.highlight {
  color: var(--green);
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/styles/about.scss"],"names":[],"mappings":"AAKE;EADF;IAEI,mBAAA;IACA,8BAAA;EAHF;AACF;;AAMA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAHF;;AAMA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAHF;AAKE;EALF;IAMI,mBAAA;IACA,8BAAA;IACA,iBAAA;EAFF;EAIE;IACE,kBAAA;IACA,iBAAA;IACA,gBAAA;EAFJ;AACF;;AAMA;EACE,mBAAA;EACA,gBAAA;AAHF","sourcesContent":[".about-page {\n  // background-color: var(--dark-grey);\n}\n\n.page-container {\n  @media (min-width: 768px) {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n}\n\n.profile-pic-container {\n  width: 300px;\n  height: 300px;\n  border-radius: 50%;\n  overflow: hidden;\n  margin: 0 auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 5px;\n  margin-top: 5px;\n}\n\n.profile-pic {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n}\n\n.profile-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  @media (min-width: 1000px) {\n    flex-direction: row;\n    justify-content: space-between;\n    max-width: 1200px;\n\n    p {\n      margin-right: auto;\n      margin-left: 20px;\n      max-width: 650px;\n    }\n  }\n}\n\n.highlight {\n  color: var(--green);\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
