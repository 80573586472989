// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  margin-right: 0px;
  list-style: none;
}

.company {
  font-weight: 600;
  font-size: 1.5em;
  color: var(--green);
  line-height: 1.5;
}

.title {
  color: var(--white);
  font-weight: 700;
  font-size: 1.2em;
  padding-bottom: 10px;
}

.date {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--light-slate);
  font-size: 0.8em;
}

.description {
  margin-top: 5px;
  margin-bottom: 40px;
  font-size: 1em;
  color: var(--lightest-slate);
  line-height: 1.5;
}`, "",{"version":3,"sources":["webpack://./src/styles/experience.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EAEA,gBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,oBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,eAAA;EACA,mBAAA;EACA,cAAA;EAEA,4BAAA;EAEA,gBAAA;AAFF","sourcesContent":["ul {\n  padding-left: 0px;\n  margin-left: 0px;\n  margin-top: 0px;\n  margin-bottom: 20px;\n  padding-top: 0px;\n  padding-bottom: 0px;\n  padding-right: 0px;\n  margin-right: 0px;\n\n  list-style: none;\n}\n\n.company {\n  font-weight: 600;\n  font-size: 1.5em;\n  color: var(--green);\n  line-height: 1.5;\n}\n\n.title {\n  color: var(--white);\n  font-weight: 700;\n  font-size: 1.2em;\n  padding-bottom: 10px;\n}\n\n.date {\n  margin-top: 10px;\n  margin-bottom: 5px;\n  font-weight: 400;\n  color: var(--light-slate);\n  font-size: 0.8em;\n}\n\n.description {\n  margin-top: 5px;\n  margin-bottom: 40px;\n  font-size: 1em;\n  //   font-weight: 400;\n  color: var(--lightest-slate);\n\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
