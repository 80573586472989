// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.menu-container .icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 3rem;
  cursor: pointer;
  z-index: 999;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--white);
}
.menu-container .menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}
.menu-container .menu ul {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transform: translateY(-20px);
  background-color: rgba(255, 255, 255, 0.95); /* Optional: to add a background color */
}
.menu-container .menu ul li {
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
}

.container-layout {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/navbar.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,wCAAA;EACA,UAAA;AACF;AACE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AAAJ;AAEI;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,4BAAA;EACA,2CAAA,EAAA,wCAAA;AAAN;AAEM;EACE,kBAAA;EACA,eAAA;EACA,eAAA;AAAR;;AAMA;EACE,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAHF","sourcesContent":[".menu-container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: var(--black);\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  z-index: 1;\n\n  .icon {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n    font-size: 3rem;\n    cursor: pointer;\n    z-index: 999;\n    padding-left: 20px;\n    padding-right: 20px;\n    color: var(--white);\n  }\n\n  .menu {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 1rem;\n    margin-top: 2rem;\n\n    ul {\n      width: 100vw;\n      height: 100vh;\n      justify-content: center;\n      align-items: center;\n      list-style: none;\n      padding: 0;\n      margin: 0;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      gap: 1rem;\n      transform: translateY(-20px);\n      background-color: rgba(255, 255, 255, 0.95); /* Optional: to add a background color */\n\n      li {\n        text-align: center;\n        font-size: 2rem;\n        cursor: pointer;\n      }\n    }\n  }\n}\n\n.container-layout {\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
