// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/green-aurora.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-heading {
  font-size: 3.2rem;
  color: white;
  margin: 0 30px 0 30px;
  padding: 0 30px 0 30px;
  text-align: center;
}

.intro-subheading {
  font-size: 1.5rem;
  text-align: center;
}

.intro-page {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  z-index: -10;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Apply blur */
  height: 500px;
}`, "",{"version":3,"sources":["webpack://./src/styles/intro.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAEA,YAAA;EACA,qBAAA;EACA,sBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,kBAAA;AAAF;;AAIA;EAKE,yDAAA;EACA,sBAAA;EACA,YAAA;EACA,mCAAA;UAAA,2BAAA,EAAA,eAAA;EAEA,aAAA;AANF","sourcesContent":[".intro-heading {\n  font-size: 3.2rem;\n  // color: var(--green);\n  color: white;\n  margin: 0 30px 0 30px;\n  padding: 0 30px 0 30px;\n  text-align: center;\n}\n\n.intro-subheading {\n  font-size: 1.5rem;\n  text-align: center;\n  // justify-self: center;\n}\n\n.intro-page {\n  // background-color: white;\n  // position: relative;\n  // background-color: white;\n  // background-image: url(../assets/images/boat-in-sea.jpg);\n  background-image: url(../assets/images/green-aurora.png);\n  background-size: cover;\n  z-index: -10;\n  backdrop-filter: blur(10px); /* Apply blur */\n  // opacity: 0.9;\n  height: 500px;\n\n  // &::after {\n  //   position: relative;\n  //   background-color: white;\n  //   opacity: 0.8;\n  //   z-index: 1;\n  // }\n}\n\n// .blur-background {\n//   position: relative;\n//   background-color: white;\n//   // opacity: 0.8;\n//   z-index: 1;\n\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
